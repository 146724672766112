import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import closeIcon from './close.svg';

const JobAlerts: React.FC = () => {
	const [popupOpened, setPopupOpened] = useState(false);

	return (
		<section className="bg-primary">
			<div
				className={
					'container mx-auto flex flex-col items-center px-5 py-10 text-center text-white'
				}
			>
				<h2 className={'font-poppins text-3xl font-bold text-white'}>
					Sign up for Job Alerts
				</h2>
				<p
					className={
						'mt-6 font-poppins text-lg font-light text-white'
					}
				>
					Join our talent community to stay up to date to receive job
					alerts.
				</p>

				<a
					className={
						'mt-8 rounded-lg bg-white p-4 px-10 font-poppins text-xl text-[#666] hover:!text-[#666] hover:opacity-90 focus:opacity-90'
					}
					href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=49d074d1-9533-4f5e-85bb-59b97576323a&ccId=19000101_000001&lang=en_US&selectedMenuKey=TalentCommunity"
					target="_blank"
					rel="noopener noreferrer"
				>
					JOIN
				</a>
			</div>
			<div
				className={[
					'fixed bottom-0 left-0 right-0 top-0 bg-[rgba(0,0,0,0.40)]',
					!popupOpened ? 'hidden' : ''
				].join(' ')}
			>
				<div
					className={
						'flex h-full w-full flex-row items-center justify-center'
					}
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							setPopupOpened(false);
						}
					}}
				>
					<div
						className={
							'container flex w-fit items-center justify-center px-5'
						}
					>
						<div
							className={
								'relative h-[85vh] max-h-[720px] min-w-[50vw] overflow-auto rounded bg-white px-12 py-4 shadow max-sm:px-5'
							}
						>
							<button
								onClick={() => {
									setPopupOpened(false);
								}}
								className="absolute right-2 top-2"
							>
								<img
									width={26}
									className={'hover:opacity-70'}
									src={closeIcon}
									alt={'close popup window'}
								/>
							</button>
							<iframe
								src="https://webforms.pipedrive.com/f/cs7oatm4XlHK99fWb9Gb7MLEf8RECfMIagC0f2tQ5PmYK35Ebrk7tpaAP1DxEpQW7F?embeded=1&amp;uuid=idlpdqsi"
								name="https://d9q0logrmbi0v.cloudfront.net/careers/-idlpdqsi"
								title="Web Forms"
								className="h-full max-h-[670px] w-full"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobAlerts;
