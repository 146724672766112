import React from 'react';
import Layout from '../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import JobAlerts from '../components/JobAlerts/JobAlerts';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout title={'JAG Careers'} desc={'JAG Careers'}>
			<div className={'container mx-auto py-20'}>
				<h1 className={'text-rubik text-bt-blue text-center text-4xl'}>
					<span className={'text-bt-red text-9xl'}>404</span> <br />{' '}
					Page Not Found.
				</h1>
			</div>
			<JobAlerts />
		</Layout>
	);
};

export default IndexPage;
